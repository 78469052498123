import React, { useEffect, useState } from "react";
import Chat from "../Components/Chat";
import Sidebar from "../Components/Sidebar";
import DashboardSidebar from "./DashboardSidebar";
import { getUserDetails } from "../store/authSlice";
import useAuth from "../utils/useAuth";
import { useAppDispatch, useAppSelector } from "../store";
import { getOrganization } from "../store/organization";
import PuffLoader from "react-spinners/PuffLoader";

function Dashboard() {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { userDetailsState, organizationState } = useAppSelector((state) => ({
    userDetailsState: state.auth.userDetails,
    organizationState: state.organization.organization,
  }));
  const [dashboard, SetDashboard] = useState(false);
  const [isOpenWebModal, setIsOpenWebModal] = useState(false);

  useEffect(() => {
    if (user && !userDetailsState) {
      dispatch(getUserDetails(user?.userId));
    }
  }, [user]);

  useEffect(() => {
    if (organizationState?.length === 0) {
      dispatch(getOrganization());
    }
  }, []);

  return (
    <>
      {
        (userDetailsState && organizationState.length > 0) ?
          <div className="dashboard-container header-height flex flex-col md:flex-row justify-center w-full">
            <div className="dashboard-sidebar-container md:h-16 ">
              <DashboardSidebar />
            </div>
            <div className="dashboard-content-container ">
              <Sidebar
                dashboard={dashboard}
                SetDashboard={SetDashboard}
                isOpenWebModal={isOpenWebModal}
                setIsOpenWebModal={setIsOpenWebModal}
              />
            </div>
            <div className={`dashboard-chat-container w-full my-pb-20 ${dashboard ? "block" : "hidden"} md:block`}>
              <Chat
                SetDashboard={SetDashboard}
              />
            </div>
          </div> :
          <div className="spinner h-screen flex items-center justify-center">
            <PuffLoader
              color={userDetailsState?.primaryColor || organizationState[0]?.primaryColor}
              cssOverride={null}
              loading
              size={60}
            />
            <h1 className="text-3xl ms-3 font-bold">Loading...</h1>
          </div>
      }
    </>
  );
}

export default Dashboard;