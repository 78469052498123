import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState, useCallback, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";

function PDFViewer({ doc }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState(null);
  const [fileContent, setFileContent] = useState("");

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onError = (error) => {
    setError(error);
  };

  const nextPage = useCallback(() => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  }, [pageNumber, numPages]);

  const prevPage = useCallback(() => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }, [pageNumber]);

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await fetch(doc.document);
        const text = await response.text();
        setFileContent(text);
      } catch (error) {
        console.error("Error fetching text file:", error);
        setError(error);
      }
    };
    if (doc?.documentName?.split(".").pop() === "txt") {
      fetchFile();
    } else {
      setFileContent("");
    }
  }, [doc]);

  return (
    <>
      <div className="flex flex-col justify-between w-[100vh] overflow-y-scroll">
        <div className="max-h-[100vh] ">
          {error && (
            <div className="text-md font-medium text-textPrimary">
              Error: {error.message}
            </div>
          )}

          {doc?.documentName?.split(".").pop() === "txt" ? (
            <pre className="w-[100vh]">{fileContent}</pre>
          ) : doc?.documentName?.split(".").pop() === "jpg" ? (
            <img src={doc.document} />
          ) : doc?.documentName?.split(".").pop() === "png" ? (
            <img src={doc.document} />
          ) : (
            <Document
              file={doc?.document}
              onLoadSuccess={onDocumentLoadSuccess}
              onError={onError}
            >
              <Page pageNumber={pageNumber} />
            </Document>
          )}
        </div>

        {numPages && !error && (
          <div className="mt-5 flex items-center justify-center">
            <button
              className=" rounded-3xl text-textPrimary text-md mr-4 flex items-center"
              onClick={prevPage}
              disabled={pageNumber === 1}
            >
              <Icon
                icon="ic:baseline-keyboard-arrow-left"
                className="text-[40px]"
              />
              Prev page
            </button>
            <p className="text-md font-medium text-secondary">
              Page
              <span className="text-lg font-semibold text-textPrimary ml-2 mr-2">
                {pageNumber}
              </span>
              of
              <span className="text-lg font-semibold text-textPrimary ml-2">
                {numPages}
              </span>
            </p>
            <button
              className=" rounded-3xl text-textPrimary ml-4 flex items-center"
              onClick={nextPage}
              disabled={pageNumber === numPages}
            >
              Next page
              <Icon
                icon="ic:round-keyboard-arrow-right"
                className="text-[40px]"
              />
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default PDFViewer;
