import { Icon } from "@iconify/react/dist/iconify.js";
import { useCallback, useEffect, useState } from "react";
import apiClient from "../../utils/axiosInstance";
import { useAppDispatch } from "../../store";
import { addMemberToOrganization, removeMemberFromOrganization, updateMemberRole } from "../../store/organization";
import SpinnerLoading from "../SpinnerLoading";
import Modal from "../Modal";
import debounce from 'lodash/debounce';
import { toast } from "react-toastify";
import CustomPricingTable from "./CustomPricingTable";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const OrganizationMembers = ({ isAdmin, userDetailsState, org, user }) => {
    const [showDeleteMemberModal, setShowDeleteMemberModal] = useState(false);
    const [inviteEmailForInvite, setInviteEmailForInvite] = useState("");
    const [emailError, setEmailError] = useState("");
    const [showStripeModal, setShowStripeModal] = useState(false);
    const [isNewInvitation, setIsNewInvitation] = useState(false);
    const [showLimitModal, setShowLimitModal] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredMembers, setFilteredMembers] = useState([]);
    const dispatch = useAppDispatch();
    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return re.test(String(email).toLowerCase());
    };
    const invitedMembers = org?.members.filter((member) => member.invitedBy === user?.userId && !member.isSubscribe)?.map((member) => member.email);

    const debouncedSearch = useCallback(
        debounce(async (term) => {
            if (term.length > 0) {
                try {
                    const response = await apiClient().post("/search-members", {
                        query: term,
                    });
                    const filteredIds = response.data.members;
                    const filtered = org.members.filter(member => filteredIds.includes(member.userId));
                    setFilteredMembers(filtered);
                } catch (error) {
                    console.error("Error searching members:", error);
                }
            } else {
                setFilteredMembers(org.members);
            }
        }, 300),
        [org.members]
    );

    useEffect(() => {
        debouncedSearch(searchTerm);
    }, [searchTerm, debouncedSearch]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleInvite = async () => {
        if (!inviteEmailForInvite.trim()) {
            setEmailError("Email is required");
            return;
        }

        if (!validateEmail(inviteEmailForInvite)) {
            setEmailError("Please enter a valid email address");
            return;
        }

        if (userDetailsState?.isSubscribe) {
            setIsNewInvitation(true);
            setShowLimitModal(true);
        } else {
            setIsLoading(true);
            const res = await apiClient().post(`/invite-user-to-organization`, {
                emails: [inviteEmailForInvite],
                email: userDetailsState?.email,
                isSubscribe: false,
            });
            dispatch(addMemberToOrganization(res.data.members[0]));
            setInviteEmailForInvite("");
            setIsLoading(false);
        }
    };

    const handlePayment = async () => {
        if (userDetailsState?.isSubscribe) {
            try {
                if (userDetailsState?.hasPaymentMethod) {
                    setIsLoading(true);
                    const response = await apiClient().post("/update-subscription-and-invite", {
                        invitedMember: inviteEmailForInvite,
                    })
                    dispatch(addMemberToOrganization(response.data.member));
                    setIsNewInvitation(false);
                    setInviteEmailForInvite("");
                } else {
                    setShowStripeModal(true);
                }
            } catch (error) {
                console.error("Error updating subscription:", error);
            } finally {
                setIsLoading(false);
                setShowLimitModal(false);
            }
        } else {
            setShowLimitModal(false);
            setShowStripeModal(true);
        }
    };

    const handleRoleChange = async (userId, newRole) => {
        try {
            setIsLoading(true);
            const res = await apiClient().put(`/update-orgMember-role/${userId}`, {
                role: newRole,
            });
            dispatch(
                updateMemberRole({ userId: res.data.userId, role: res.data.role })
            );
        } catch (error) {
            console.error("Error updating role:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRemoveMember = (member) => {
        setSelectedMember(member);
        setShowDeleteMemberModal(true);
    };

    const confirmRemoveMember = async () => {
        try {
            setIsLoading(true);
            const res = await apiClient().delete(
                `/remove-user-to-organization/${selectedMember.userId}`
            );
            dispatch(removeMemberFromOrganization(res.data.userId));
        } catch (error) {
            console.log("Error removing member:", error);
        } finally {
            setShowDeleteMemberModal(false);
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <SpinnerLoading isLoading={true} />}
            <div className="account-table w-full">
                <div className="md: py-2 rounded-xl ">
                    <div className="flex flex-col md:flex-row items-start justify-between mb-4 space-y-4 md:space-y-0 md:space-x-4">
                        {/* <div className="w-full md:w-1/3">
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Search members..."
                                className="border p-2 rounded w-full"
                            />
                        </div> */}
                        {isAdmin && (
                            <div className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-4 w-full">
                                <div className="flex flex-col w-full">
                                    <div className="flex items-center w-full gap-5">
                                        <input
                                            type="email"
                                            value={inviteEmailForInvite}
                                            onChange={(e) => {
                                                setInviteEmailForInvite(e.target.value);
                                                setEmailError("");
                                            }}
                                            placeholder="Enter email to invite"
                                            className={`outline-none border border-[#EAEAEB] p-2 px-5  rounded-full w-[80%] ${emailError ? "border-red-500" : ""}`}
                                        />
                                        <button
                                            onClick={handleInvite}
                                            style={{ color: org?.primaryColor }}
                                            className=" border border-[#7E7E7E] w-[20%] rounded-full flex items-center justify-center p-2 ml-2 whitespace-nowrap background: linear-gradient(96.32deg, #8934E9 18.87%, #5844E6 95.96%);
"
                                        >

                                            Invite Member
                                        </button>
                                    </div>
                                    {emailError && (
                                        <p className="text-red-500 text-sm mt-1">{emailError}</p>
                                    )}
                                </div>
                                {!userDetailsState?.isSubscribe && (
                                    <button
                                        onClick={() => { setShowStripeModal(true) }}
                                        className="bg-lendingButton text-white px-4 py-2 rounded flex items-center whitespace-nowrap"
                                    >
                                        Subscribe <Icon icon="quill:link-out" width="1.2em" height="1.2em" className="ml-1" />
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="flex items-center w-full mt-10 mb-10">
                        <h1 className="w-[70%] text-2xl">Invited Members</h1>
                        <div className="w-[30%]">
                            <Icon icon="bitcoin-icons:search-outline" className="absolute z-50 mt-1 ml-3" width="2rem" height="2rem" />
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Search members"
                                className="border border-[#EAEAEB] p-2 rounded-full px-14 w-[250px]"
                            />
                        </div>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="w-full ">
                            <thead>
                                <tr className="border-b">
                                    <td className="p-2 text-left text-slate-600 font-medium">EMAIL</td>
                                    <td className="p-2 text-left text-slate-600 font-medium">ROLE</td>
                                    <td className="p-2 text-left text-slate-600 font-medium">INVITED BY</td>
                                    {/* <td className="p-2 text-left text-slate-600 font-medium">Subscription</td> */}
                                    {/* {(isAdmin && org?.members?.length > 1) && <th className="p-2 text-left text-slate-600 font-medium">Action</th>} */}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredMembers?.map((member) => (
                                    <tr key={member.userId} className="border-b">
                                        <td className="p-4 flex items-center align-center">
                                            {member.email}
                                            {member.isAccepted ? (
                                                <IoMdCheckmarkCircleOutline className="ml-1 text-green-500" />
                                                // <Icon icon="mdi:check-circle"  />
                                            ) : (
                                                <Icon icon="mdi:close-circle" className="ml-1 mb-1 text-red-500" width="20" height="20" />
                                            )}
                                        </td>
                                        <td className="p-4">
                                            {(isAdmin && !member.isRootAdmin) ? (
                                                member.userId === user?.userId ? (
                                                    <p className="text-base font-normal capitalize">
                                                        {member.role}
                                                    </p>
                                                ) : (
                                                    <select
                                                        value={member.role}
                                                        onChange={(e) =>
                                                            handleRoleChange(
                                                                member.userId,
                                                                e.target.value
                                                            )
                                                        }
                                                        className="border border-[#EAEAEB] rounded-full p-1 px-5"
                                                    >
                                                        <option value="admin">Admin</option>
                                                        <option value="member">Member</option>
                                                    </select>
                                                )
                                            ) : (
                                                <p className="text-base font-normal capitalize">
                                                    {member.role}
                                                </p>
                                            )}
                                        </td>
                                        <td className="p-4">{member.isRootAdmin ? "" : member.invited}</td>
                                        {
                                            member.subscriptionStatus !== "active" && (
                                                <td className="p-4">
                                                    {member.subscriptionStatus}
                                                </td>
                                            )
                                        }

                                        {((isAdmin && !member.isRootAdmin) && (member.userId !== user?.userId)) && (
                                            <td className="p-2">
                                                <button
                                                    onClick={() => handleRemoveMember(member)}
                                                    className=" text-red-400 hover:text-red-500 px-2 py-1 rounded"
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Modal
                showModal={showLimitModal}
                setShowModal={setShowLimitModal}
                confirmAction={handlePayment}
                label={userDetailsState?.hasPaymentMethod ? "Invite and update subscription" : "Pay and Invite"}
                description={userDetailsState?.hasPaymentMethod ? "We'll update your current subscription by adding 1 member to your existing plan." : "You need to subscribe to invite more members"}
                confirmText={userDetailsState?.hasPaymentMethod ? "update and invite" : "Pay & Invite"}
                cancelText={"Cancel"}
            />

            {showStripeModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-md w-full">
                        <div className="p-4 bg-gray-100 flex justify-between items-center">
                            <h2 className="text-lg ">Subscribe</h2>
                            <button
                                onClick={() => {
                                    setShowStripeModal(false);
                                    setSelectedMember(null);
                                }}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                <Icon icon="heroicons:x-mark" width="24" height="24" />
                            </button>
                        </div>
                        <CustomPricingTable
                            setShowStripeModal={setShowStripeModal}
                            invitedMembers={isNewInvitation ? [inviteEmailForInvite] : invitedMembers}
                            isNewInvitation={isNewInvitation}
                            userDetails={userDetailsState}
                        />
                    </div>
                </div>)}

            {isAdmin && (
                <Modal
                    showModal={showDeleteMemberModal}
                    setShowModal={setShowDeleteMemberModal}
                    confirmAction={confirmRemoveMember}
                    label={"Remove Member"}
                    description={`Are you sure you want to remove ${selectedMember?.email} from the organization?`}
                    confirmText={"Confirm"}
                    cancelText={"Cancel"}
                />
            )}
        </>
    )
}

export default OrganizationMembers;