import { useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import DashboardSidebar from "./DashboardSidebar";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useAppDispatch, useAppSelector } from "../store";
import { getOrganization } from "../store/organization";
import { useNavigate } from "react-router-dom";
import useAuth from "../utils/useAuth";
import PuffLoader from "react-spinners/PuffLoader";

function DMSPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { organizationState, userDetailsState } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
    userDetailsState: state.auth.userDetails
  }));
  const dms = organizationState[0]?.members?.filter((member) => member.userId !== user?.userId && member.isAccepted === true);

  useEffect(() => {
    if (organizationState) {
      dispatch(getOrganization());
    }
  }, []);

  return (
    <>
      {(userDetailsState && organizationState.length > 0) ?
        <div>
          <div className="flex md:flex-row flex-col">
            <DashboardSidebar />
            <Sidebar />
            <div className={`bg-backgroundColor header-height w-full md:px-0 px-2 my-pb-20`}>
              <div>
                <div className="px-4 py-3 flex justify-between items-center border-b-2">
                  <div className="flex items-center ">
                    <p className="text-xl flex items-center text-textPrimary font-medium">
                      <Icon
                        icon="flowbite:messages-outline"
                        width="1.3em"
                        height="1.3em"
                        className="mr-2"
                      />
                      Direct Messages
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className="flex flex-col">
                    {organizationState &&
                      dms?.map((member, index) => {
                        return (
                          <div
                            key={index}
                            className="border-b-2 p-4"
                            onClick={() => navigate(`/dm?memberId=${member.userId}`)}
                          >
                            <p className="flex items-center text-lg font-normal capitalize">
                              <Icon
                                icon="tdesign:member"
                                width="1.2em"
                                height="1.2em"
                                className="mr-1"
                              />
                              {member.name}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="spinner h-screen flex items-center justify-center">
          <PuffLoader
            color={userDetailsState?.primaryColor || organizationState[0]?.primaryColor}
            cssOverride={null}
            loading
            size={60}
          />
          <h1 className="text-3xl ms-3 font-bold">Loading...</h1>
        </div>
      }

    </>
  );
}

export default DMSPage;