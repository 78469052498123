import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import useAuth from "../../utils/useAuth";
import { resetMemberNotification } from "../../store/organization";
import apiClient from "../../utils/axiosInstance";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useEffect, useState } from "react";

function SidebarRenderDMs({ isItemActive, setActiveItemId }) {
    const location = useLocation();
    const { user } = useAuth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [dms, setDms] = useState([]);
    const { organizationState } = useAppSelector((state) => ({
        organizationState: state.organization.organization,
    }));

    useEffect(() => {
        if (user && location.pathname==="/dm") {
            const dms = organizationState[0]?.members?.filter((member) => member.userId !== user?.userId && member.isAccepted === true);
            setDms(dms);
            if (dms?.length > 0) {
                const member = dms[0];
                setActiveItemId(member.userId);
                navigate(`/dm?id=${member.userId}`);
            }
        }
    }, [location?.state, user]);

    const handleItemClick = (itemId) => {
        setActiveItemId(itemId);
    };

    const resetOrgMemberNotification = async (memberId) => {
        try {
            await apiClient().put(`/reset-notification/${memberId}`);
            dispatch(
                resetMemberNotification({ memberId: memberId, notification: 0 })
            );
        } catch (error) {
            console.error("Error resetting notification:", error);
        }
    };

    return (
        <ul className="space-y-2 px-3 py-1 ">
            <li>
                <div className="flex items-center justify-between py-5 border-b-2">
                    <p className="text-xl font-bold">Direct Messages</p>
                </div>
            </li>
            {dms?.length === 0 ? (
                <div className="flex justify-center">
                    <p className="text-secondary text-sm ">No dms available</p>
                </div>
            ) : (dms?.map((item, index) => (
                <li
                    key={index}
                    className={`text-sm flex items-center overflow-y-auto p-1 rounded-lg transition-colors duration-200`}
                    style={{
                        background: isItemActive(item.userId) ? organizationState[0]?.secondaryColor : "transparent",
                    }}
                >
                    <div className="relative mr-2">
                        <Icon
                            icon="heroicons:user-circle"
                            width="1.8em"
                            height="1.8em"
                            className="text-pricingText"
                            style={{
                                color: isItemActive(item.userId) ? "white" : "black"  
                              }}
                        />
                        <span
                            className={`absolute bottom-0 right-0 w-2 h-2 rounded-full ${item.user_status === "online" ? "bg-green-500" : "bg-gray-500"
                                }`}
                        ></span>
                    </div>
                    <Link
                        to={`/dm?id=${item.userId}`}
                        style={{
                            color: isItemActive(item.userId) ? "white" : "black"  
                          }}
                        onClick={(e) => {
                            handleItemClick(item.userId);
                            if (item.notification > 0) {
                                e.preventDefault();
                                resetOrgMemberNotification(item.userId).then(() => {
                                    navigate(`/dm?id=${item.userId}`);
                                });
                            }
                        }}
                        className={`truncate w-40 flex-grow text-landing capitalize  font-normal flex items-center justify-between`}
                    >
                        <span >{item.name}</span>
                        {item.notification > 0 && (
                            <span className="bg-red-500 text-white text-xs font-bold w-5 h-5 p-[1.5px] rounded-full ml-2 text-center">
                                {item.notification}
                            </span>
                        )}
                    </Link>
                </li>
            )))}
            {dms?.length > 10 && <button
                className=" font-normal text-center ml-2 border-b-2 "
                style={{ color: `${organizationState[0]?.primaryColor}`, borderColor: `${organizationState[0]?.primaryColor}` }}
                onClick={() => navigate("/dms")}
            >
                View All
            </button>
            }
        </ul >
    );
}

export default SidebarRenderDMs;
