import React, { useState } from 'react';
import apiClient from '../../utils/axiosInstance';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import SpinnerLoading from '../SpinnerLoading';

const CustomPricingTable = ({ setShowStripeModal, invitedMembers, isNewInvitation, userDetails }) => {
    console.log('✌️isNewInvitation --->', isNewInvitation);
    console.log('✌️userDetails --->', userDetails);
    console.log('✌️invitedMembers CustomPricingTable--->', invitedMembers);
    const [billingCycle, setBillingCycle] = useState(isNewInvitation ? userDetails.planInterval : 'day');
    const [loading, setLoading] = useState(false);

    const plans = {
        day: {
            basePrice: 5,
            interval: 'per day',
            features: [
                'Unlimited Credits',
                '10GB of storage',
            ]
        },
        week: {
            basePrice: 60,
            interval: 'per week',
            features: [
                'Unlimited Credits',
                '10GB of storage',
            ]
        }
    };

    const calculateTotalPrice = () => {
        return plans[billingCycle].basePrice * invitedMembers.length;
    };

    const handlePurchase = async () => {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

        if (!stripe) {
            return;
        }
        try {
            setLoading(true);
            const response = await apiClient().post("/create-checkout-session", {
                planInterval: billingCycle,
                quantity: invitedMembers.length,
                invitedMembers: invitedMembers
            });
            const session = await response.data;
            if (session.sessionId) {
                await stripe.redirectToCheckout({
                    sessionId: session.sessionId,
                });
            }
        } catch (error) {
            toast.error(error);
        } finally {
            setLoading(false);
            setShowStripeModal(false);
        }
    };

    return (
        <>
            {loading && <SpinnerLoading isLoading={true} />}
            <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden">
                <div className="p-8">
                    {!isNewInvitation && (
                        <div className="flex justify-center mb-4">
                            <select
                                className="border border-gray-300 rounded-md px-2 py-1 text-sm"
                                value={billingCycle}
                                onChange={(e) => setBillingCycle(e.target.value)}
                            >
                                <option value="day">Daily</option>
                                <option value="week">Weekly</option>
                            </select>
                        </div>
                    )}

                    <div className="text-center my-6">
                        <span className="text-4xl font-bold">US${calculateTotalPrice()}</span>
                        <span className="text-gray-500 ml-2">{plans[billingCycle].interval}</span>
                    </div>

                    <div className="text-center mb-6">
                        <span className="text-lg">US${plans[billingCycle].basePrice} per member</span>
                        <span className="text-gray-500 ml-2">× {invitedMembers.length} {invitedMembers.length > 1 ? 'members' : 'member'}</span>
                    </div>

                    <button
                        className="w-full py-3 px-4 rounded-md font-medium transition duration-200 bg-teal-700 text-white hover:bg-teal-800"
                        onClick={handlePurchase}
                    >
                        Subscribe
                    </button>

                    <div className="mt-8">
                        <h3 className="text-lg font-semibold mb-4">This includes:</h3>
                        <ul className="space-y-3">
                            {plans[billingCycle].features.map((feature, index) => (
                                <li key={index} className="flex items-start">
                                    <svg className="w-5 h-5 text-gray-400 mr-2 mt-1" fill="currentColor" viewBox="0 0 20 20">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                    </svg>
                                    <span className="text-gray-600">{feature}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomPricingTable;