import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { io } from "socket.io-client";
import { jwtDecode } from "jwt-decode";
import { useAppDispatch, useAppSelector } from "../store";
import {
  updateMemberStatuses,
  updateTeamNotification,
} from "../store/teamSlice";
import {
  updateChatNotification,
  updateMainThreadNotification,
  addNewChat,
} from "../store/finChat";
import { updateMemberNotification, updateOrgMemberStatuses } from "../store/organization";

const socketContext = createContext();
export const useSocket = () => useContext(socketContext);

export const SocketProvider = ({ children }) => {
  const dispatch = useAppDispatch();
  const decodedToken = jwtDecode(localStorage.getItem("authToken"));
  const [socketId, setSocketId] = useState();
  const { teamState, threadChatState, chatWithLimitState, organizationState } = useAppSelector(
    (state) => ({
      teamState: state.teams.teams,
      threadChatState: state.thread.threadChats,
      chatWithLimitState: state.finChat.chatWithLimit,
      organizationState: state.organization.organization,
    })
  );

  useEffect(() => {
    if (!socketId) return;

    socket.on("user_joined", ({ email }) => {
      if (teamState?.length > 0) {
        dispatch(updateMemberStatuses({ email, status: "online" }));
      }
      if (organizationState?.length > 0) {
        dispatch(updateOrgMemberStatuses({ email, status: "online" }));
      }
    })

    socket.on("user_left", ({ email }) => {
      if (teamState?.length > 0) {
        dispatch(updateMemberStatuses({ email, status: "offline" }));
      }
      if (organizationState?.length > 0) {
        dispatch(updateOrgMemberStatuses({ email, status: "offline" }));
      }
    });

    if ("Notification" in window && Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        } else {
          console.log("Notification permission denied.");
        }
      });
    }

    socket.on("tag_member", ({ thread }) => {
      if ("Notification" in window && Notification.permission === "granted") {
        new Notification("askfin", {
          body: `New  "${thread?.chatName}" thread created by ${thread?.senderName}`,
          icon: "https://webstockreview.net/images/notification-icon-png-5.png",
        });
      }
      dispatch(updateMainThreadNotification());
      dispatch(addNewChat(thread));
    });

    if (chatWithLimitState?.history?.length > 0) {
      socket.on("new_main_thread_message", ({ threadId, absentMembers }) => {
        if (absentMembers.includes(decodedToken?.userId)) {
          const chat = chatWithLimitState.history.find(
            (chat) => chat.threadId === threadId
          );
          if (
            "Notification" in window &&
            Notification.permission === "granted"
          ) {
            new Notification("askfin", {
              body: `New message in "${chat.chatName}" thread`,
              icon: "https://webstockreview.net/images/notification-icon-png-5.png",
            });
          }
          dispatch(
            updateChatNotification({
              threadId: threadId,
              notification: chat?.notification ? chat.notification + 1 : 1,
            })
          );
        }
      });
    }

    if (teamState?.length > 0) {
      socket.on("new_space_message", ({ teamId, absentMembers, notificationName }) => {
        if (absentMembers.includes(decodedToken?.userId)) {
          const team = teamState.find((team) => team.teamId === teamId);
          if (
            "Notification" in window &&
            Notification.permission === "granted"
          ) {
            new Notification("askfin", {
              body: `New message in "${notificationName}" team`,
              icon: "https://webstockreview.net/images/notification-icon-png-5.png",
            });
          }
          dispatch(
            updateTeamNotification({
              teamId: teamId,
              notification: team?.notification ? team.notification + 1 : 1,
            })
          );
        }
      });
    }

    if (organizationState?.length > 0) {
      socket.on("direct_message", ({ senderId, receiverId }) => {
        if (decodedToken.userId === receiverId) {
          const member = organizationState[0].members.find(
            (member) => member.userId === senderId
          );
          if ("Notification" in window && Notification.permission === "granted") {
            new Notification("askfin", {
              body: `${member?.name} sent you a message`,
              icon: "https://webstockreview.net/images/notification-icon-png-5.png",
            });
          }
          dispatch(updateMemberNotification({ memberId: senderId }));
        }
      });
    }

    if (organizationState?.length > 0) {
      socket.on("direct_reply_message", ({ senderId, receiverId }) => {
        if (decodedToken.userId === receiverId) {
          const member = organizationState[0].members.find(
            (member) => member.userId === senderId
          );
          if ("Notification" in window && Notification.permission === "granted") {
            new Notification("askfin", {
              body: `${member?.name} sent you a message`,
              icon: "https://webstockreview.net/images/notification-icon-png-5.png",
            });
          }
          dispatch(updateMemberNotification({ memberId: senderId }));
        }
      });
    }

    return () => {
      socket.off("user_joined");
      socket.off("user_left");
      socket.off("new_main_thread_message");
      socket.off("tag_member");
      socket.off("new_space_message");
      socket.off("direct_message");
      socket.off("direct_reply_message");
    };
  }, [chatWithLimitState, threadChatState, teamState, socketId, dispatch, organizationState]);
  const socket = useMemo(
    () =>
      io(process.env.REACT_APP_API_URL, {
        query: { userId: decodedToken?.userId },
        withCredentials: true,
      }),
    []
  );
  socket.on("connect", () => {
    console.log("connected", socket.id);
    setSocketId(socket.id);
  });
  const value = {
    socket,
    socketId,
  };
  return (
    <socketContext.Provider value={value}>{children}</socketContext.Provider>
  );
};
