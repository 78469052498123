import { useRef, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import posthog from "posthog-js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import useAuth from "../../utils/useAuth";
import { useAppDispatch } from "../../store";
import { updateOrganizationKeyValue, uploadLogo } from "../../store/organization";
import apiClient from "../../utils/axiosInstance";
import SpinnerLoading from "../SpinnerLoading";
import AIModelsSection from "../AIModelsSection";
import Modal from "../Modal";
import NewPasswordModal from "../NewpasswordModal";
import { updateUserDetails } from "../../store/authSlice";

function AccountSetting({ userDetailsState, org }) {
    const { handleLogOut } = useAuth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [newPasswordModal, setNewPasswordModal] = useState(false);
    const [showFinalConfirmationModal, setShowFinalConfirmationModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isEditingImage, setIsEditingImage] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState(null);
    const [newUserName, setNewUserName] = useState(userDetailsState?.name);
    const [newUserImage, setNewUserImage] = useState(userDetailsState.userPictureUrl);
    const fileInputRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleLogoutFun = () => {
        handleLogOut();
    };
    const handleDeleteAccount = () => {
        setShowModal(true);
    };

    const confirmDeleteAccount = async () => {
        try {
            setIsLoading(true);
            const response = await apiClient().delete("/account");
            if (response.data && response.status === 200) {
                posthog.reset(true);
                dispatch(handleLogOut());
                navigate("/");
            }
        } catch (error) {
            toast.error("Error while deleting account");
        } finally {
            setIsLoading(false);
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleImageChange = (e) => {
        if (e.target.files.length > 0) {
            setNewUserImage(e.target.files[0])
        }
    }


    const handleSave = async () => {
        try {
            if (newUserName === userDetailsState?.name) {
                setIsEditing(false);
                return;
            }
            const formData = new FormData();
            formData.append('name', newUserName);
            const response = await apiClient().put("/update-profile", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            dispatch(updateUserDetails({ name: response.data.name }));
        } catch (error) {
            console.error("Error updating User name:", error);
        } finally {
            setIsEditing(false);
        }
    };

    const handleSaveImage = async () => {
        try {
            if (!newUserImage || !(newUserImage instanceof File)) {
                toast.error("Please select a valid image before uploading.");
                return;
            }
            const formData = new FormData();
            formData.append('photo', newUserImage);

            if (!formData.has('photo')) {
                toast.error("No image found in FormData.");
                return;
            } else {

                setIsLoading(true);
                const response = await apiClient().put("/update-profile", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                dispatch(updateUserDetails({ userPictureUrl: response.data.photo }));
                setIsLoading(false);
                setIsModalOpen(false);
            }
        } catch (error) {
            console.error("Error updating User Image:", error);
            toast.error("Error updating image, please try again.");
            setIsLoading(false);
        } finally {
            setIsEditingImage(false);
            setIsLoading(false);
        }
    }



    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setIsUploading(true);
        setError(null);

        const formData = new FormData();
        formData.append('logo', file);

        try {
            const config = { headers: { "Content-Type": "multipart/form-data" } };
            const response = await apiClient().post('/logo', formData, config);
            dispatch(uploadLogo(response.data));
        } catch (err) {
            setError('Failed to upload logo. Please try again.');
        } finally {
            setIsUploading(false);
        }
    };

    const handleCancle = () => {
        setIsEditing(false);
    }




    return (
        <>
            {(isLoading || isUploading) && <SpinnerLoading isLoading={true} />}
            {(userDetailsState && org) &&
                <div className="bg-white rounded-lg w-full m-auto">
                    <div className="account-details">
                        <div className="rounded-xl">
                            <div className="flex items-center justify-between border-b-2 border-borderColor pb-5">
                                <p className="text-lg font-normal text-secondary w-[40%]">Profile Picture</p>
                                <div className="flex w-[40%]">
                                    <div className="relative">
                                        {userDetailsState?.userPictureUrl ? (
                                            <img
                                                src={userDetailsState.userPictureUrl}
                                                alt="Profile"
                                                className="rounded-full h-12 w-12 object-cover hover:cursor-pointer"
                                            // onClick={() => setIsModalOpen(true)} 
                                            />
                                        ) : (
                                            <div className="text-white text-2xl rounded-full h-12 w-12 flex items-center justify-center cursor-pointer" style={{ backgroundColor: org?.secondaryColor }} onClick={() => setIsModalOpen(true)}>
                                                {userDetailsState?.name?.charAt(0).toUpperCase()}
                                            </div>
                                        )}

                                    </div>
                                    {isLoading ? (
                                        <div className="flex justify-center items-center py-4">
                                            <SpinnerLoading isLoading={true} />
                                        </div>
                                    ) : (
                                        isModalOpen && (
                                            <modal
                                                onClose={() => setIsModalOpen(false)}
                                                className="fixed pl-50 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center "
                                            >
                                                <div className="p-4 md:p-8 rounded-lg bg-white md:w-[600px] w-[350px]">
                                                    <div className="flex items-center justify-between pb-10">
                                                        <h1 className="text-2xl text-center font-bold ">Upload Image</h1>
                                                        <Icon
                                                            icon="fa:times"
                                                            className="cursor-pointer text-md"
                                                            width="1.2em"
                                                            height="1.2em"
                                                            onClick={() => setIsModalOpen(false)}
                                                        />
                                                    </div>
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        id="userPictureUrl"
                                                        name="userPictureUrl"
                                                        onChange={handleImageChange}
                                                        ref={fileInputRef}
                                                        className="my-2 bg-gray-200 w-full p-2 m-2 rounded-lg mb-5"
                                                    />
                                                    <div className="flex items-center justify-around pt-2">
                                                        <button
                                                            onClick={handleSaveImage}
                                                            className="mt-2 px-4 py-2 bg-green-400 hover:bg-green-500 text-white rounded"
                                                        >
                                                            Upload
                                                        </button>
                                                        <button
                                                            onClick={() => setIsModalOpen(false)}
                                                            className="mt-2 px-4 py-2 bg-red-400 hover:bg-red-500 text-white rounded"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </modal>
                                        )
                                    )}
                                </div>
                                <button className="text-lg w-[20%]" style={{ color: org?.primaryColor }} onClick={() => setIsModalOpen(true)} >Update</button>
                            </div>
                            <div className="flex items-center justify-between border-b-2 mt-5 border-borderColor pb-5">
                                <p className="text-lg font-normal text-secondary w-[40%]">UserName</p>
                                <div className="flex w-[40%]">
                                    {isEditing ? (
                                        <>
                                            <input
                                                type="text"
                                                value={newUserName}
                                                onChange={(e) => setNewUserName(e.target.value)}
                                                className="text-base font-normal text-textPrimary mr-2 py-2 border border-borderColor rounded-full"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <p className="text-base font-normal  w-[250px] py-2 rounded-full   text-textPrimary mr-2">
                                                {userDetailsState?.name}
                                            </p>
                                        </>
                                    )}
                                </div>
                                {isEditing && (
                                    <button
                                        className="text-lg w-[20%]  text-slate-400"
                                        onClick={handleCancle}
                                    >
                                        Cancel
                                    </button>
                                )}
                                <button
                                    className="text-lg w-[20%]"
                                    style={{ color: org?.primaryColor }}
                                    onClick={isEditing ? handleSave : handleEdit}
                                >
                                    {isEditing ? 'Save' : 'Update'}
                                </button>
                            </div>

                            {/* <div className="flex items-center justify-between border-b-2 mt-5 border-borderColor pb-5">
                                <p className="text-lg font-normal text-secondary">UserName</p>
                                <div className="flex">
                                    {isEditing ? (
                                        <>
                                            <input
                                                type="text"
                                                value={newUserName}
                                                onChange={(e) => setNewUserName(e.target.value)}
                                                className="text-base font-normal text-textPrimary mr-2 p-1 border rounded"
                                            />
                                            <Icon icon="pajamas:false-positive" className="cursor-pointer" width="1.2em" height="1.2em" onClick={handleSave} />
                                        </>
                                    ) : (
                                        <>
                                            <p className="text-base font-normal text-textPrimary mr-2">
                                                {userDetailsState?.name}
                                            </p>
                                            <Icon icon="fa:edit" className="cursor-pointer text-green-700" width="1.2em" height="1.2em" onClick={handleEdit} />
                                        </>
                                    )}
                                </div>
                                <button className="text-lg" style={{ color: org?.primaryColor }}>Update</button>
                            </div> */}
                            <div className="flex items-center justify-between border-b-2 border-borderColor mt-5 pb-5">
                                <p className="text-lg font-normal text-secondary w-[40%]">Email</p>
                                <div className="flex w-[40%]">
                                    <p className="text-base font-normal text-textPrimary">
                                        {userDetailsState?.email}
                                    </p>
                                </div>
                                <div className="w-[20%]"></div>
                            </div>
                        </div>
                    </div>
                    <div className="account-system mt-8 pr-10">
                        <div className=" rounded-xl flex items-center justify-end gap-10">

                            <div className="flex items-center  justify-between  border-borderColor">
                                <div className="flex">
                                    <button
                                        onClick={handleDeleteAccount}
                                        className="text-md font-normal  text-red-500 py-2 px-5 border-2 rounded-full  w-full transition-colors duration-300"
                                    >
                                        Delete Account
                                    </button>
                                </div>
                            </div>
                            <div className="flex items-center justify-between  border-borderColor">
                                <div className="flex">
                                    <button
                                        onClick={handleLogoutFun}
                                        className="flex items-center py-2 px-5  border-2 text-center text-md font-medium rounded-full text-textPrimary"
                                    >
                                        Logout
                                    </button>
                                </div>
                            </div>

                            <div className="flex items-center justify-between ">
                                <div className="flex">
                                    <button
                                        style={{ backgroundColor: org?.primaryColor }}
                                        onClick={() => setNewPasswordModal(!newPasswordModal)}
                                        className=" text-md font-normal border-2 text-white py-2 px-5 rounded-full w-full transition-colors duration-300"
                                    >
                                        Update Password
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                confirmAction={confirmDeleteAccount}
                label={"Delete Account"}
                description={"Are you sure you want to delete your account?"}
                confirmText={"Confirm"}
                cancelText={"Cancel"}
            />

            <Modal
                showModal={showFinalConfirmationModal}
                setShowModal={setShowFinalConfirmationModal}
                confirmAction={confirmDeleteAccount}
                label="Confirm Account Deletion"
                description={`Are you sure you want to delete account?`}
                confirmText="Confirm"
                cancelText="Cancel"
            />

            <NewPasswordModal
                newPasswordModal={newPasswordModal}
                setNewPasswordModal={setNewPasswordModal}
            />
        </>
    );
}

export default AccountSetting;