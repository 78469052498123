import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useAppSelector } from "../../store";
import { useDispatch } from "react-redux";
import {
  deleteUserLimitedChat,
  getUserChatsWithinLimit,
  updateLimitedChat,
} from "../../store/finChat";
import moment from "moment/moment";
import apiClient from "../../utils/axiosInstance";
import DeleteModal from "../DeleteModal";
import { useNavigate } from "react-router-dom";
import SpinnerLoading from "../SpinnerLoading";
import Markdown from "react-markdown";

function History() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const { historyState, status, chatLoadStatus } = useAppSelector((state) => ({
    historyState: state.finChat.chatWithLimit,
    chatLoadStatus: state.finChat.chatLoadStatus,
    status: state.finChat.status,
  }));
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [chatEdit, setChatEdit] = useState(null);
  const [chatValueChange, setChatValueChange] = useState();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteChat, setDeleteChat] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(
    historyState?.history?.length > 6 ? historyState?.history?.length : 6 || 6
  );
  const [totalChats, setTotalChats] = useState(historyState?.chats || 0);

  useEffect(() => {
    const fetchChats = async () => {
      if (limit > 8) {
        const response = await dispatch(getUserChatsWithinLimit(limit));
        setTotalChats(response.payload.chats);
      }
    };
    fetchChats();
  }, [limit]);

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const toggleDropdown = (threadId) => {
    setOpenDropdownId(openDropdownId === threadId ? null : threadId);
  };

  const handleEdit = (threadId) => {
    setChatEdit(threadId);
    const chat = historyState?.history.find(
      (chat) => chat.threadId === threadId
    );
    setChatValueChange(chat?.chatName);
    setOpenDropdownId(false);
  };

  const handleKeyPress = async () => {
    if (chatEdit !== null) {
      const chat = historyState.history.find(
        (chat) => chat.threadId === chatEdit
      );
      const body = {
        chatName: chatValueChange,
      };
      try {
        setIsLoading(true);
        const response = await apiClient().put(`/chat/${chat?.threadId}`, body);
        dispatch(updateLimitedChat(response.data));
        setChatEdit(null);
      } catch (error) {
        console.log("error in chat slice:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClickOutside = (event) => {
    if (
      openDropdownId?.current &&
      !openDropdownId?.current?.contains(event?.target)
    ) {
      setOpenDropdownId(null);
    }

    if (
      chatEdit !== null &&
      inputRef?.current &&
      !inputRef?.current?.contains(event.target)
    ) {
      handleKeyPress();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDeleteChat = async (threadId) => {
    try {
      setIsLoading(true);
      const id = threadId;
      const response = await apiClient().delete(`/chat/${id}`);
      dispatch(deleteUserLimitedChat(response?.data?.threadId));
      setOpenDropdownId(false);
      setIsDeleteModal(false);
    } catch (error) {
      console.log("error in chat slice:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (threadId) => {
    setDeleteChat(threadId);
    setIsDeleteModal(true);
  };

  const handleInputChange = (e) => {
    setChatValueChange(e.target.value);
  };

  const getChatByChatId = async (_idChat, type) => {
    if (type === "chat") {
      const threadId = _idChat;
      navigate(`/answer-search?id=${threadId}`, { state: { newChat: true } });
    } else {
      const threadId = _idChat;
      navigate(`/thread?id=${threadId}`, { state: { newChat: true } });
    }
  };

  const handleScroll = useCallback(
    debounce((e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      const isNearBottom = scrollHeight - scrollTop <= clientHeight + 50;
      if (isNearBottom && limit < totalChats) {
        setLimit((prevLimit) => prevLimit + 6);
      }
    }, 200),
    [limit, totalChats]
  );

  return (
    <>
      {(status === "loading" || isLoading) && (
        <SpinnerLoading isLoading={true} />
      )}
      <div className="">
        <div className="px-8 py-6 flex items-center">
          <Icon icon="fluent:layer-24-regular" fontSize={30} />
          <p className="text-xl text-textPrimary font-medium">History</p>
        </div>

        <div
          className="overflow-y-scroll"
          style={{ height: "calc(100vh - 140px)" }}
          onScroll={handleScroll}
        >
          {historyState?.history?.map((item) => {
            const formattedDate = moment(item.createdAt).fromNow();
            return (
              <div
                className="cursor-pointer"
                key={item.threadId}
              >
                <div className="border-t-2 border-borderColor">
                  <div className="px-8 text-md lg:text-lg flex items-center justify-between text-textPrimary font-medium mb-3 mt-3 relative">
                    <div
                      onClick={() => getChatByChatId(item.threadId, item.type)}
                    >
                      {chatEdit === item.threadId ? (
                        <input
                          ref={inputRef}
                          type="text"
                          id="Chat"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mx-auto block w-full p-2.5"
                          placeholder="Chat"
                          onChange={handleInputChange}
                          defaultValue={item.chatName}
                          value={chatValueChange}
                          onBlur={handleKeyPress}
                          onKeyDown={(e) => {
                            if (e?.key === "Enter") handleKeyPress();
                          }}
                          autoFocus
                        />
                      ) : (
                        <p
                          className="text-md lg:text-lg text-textPrimary font-medium relative"
                          onClick={() =>
                            getChatByChatId(item.threadId, item.type)
                          }
                        >
                          {item.chatName}
                        </p>
                      )}
                    </div>
                    <div className="flex items-center">
                      <button
                        className=""
                        onClick={() => toggleDropdown(item.threadId)}
                      >
                        <Icon
                          icon="charm:menu-kebab"
                          className="text-secondary mr-2"
                        />
                      </button>
                    </div>
                    {openDropdownId === item.threadId && (
                      <div className=" absolute top-10 right-0 z-10 bg-white shadow-lg rounded-md py-2">
                        <button
                          className="p-2 w-48 hover:bg-gray-100 font-medium cursor-pointer flex items-center"
                          onClick={() => handleEdit(item.threadId)}
                        >
                          <Icon icon="fa:edit" className="mr-1" />
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(item.threadId)}
                          className="p-2 w-48 hover:bg-gray-100 cursor-pointer text-red-700 font-medium flex items-center"
                        >
                          <Icon
                            icon="fluent:delete-24-regular"
                            className="mr-1 text-xl"
                          />
                          Delete
                        </button>
                      </div>
                    )}
                  </div>

                  <p
                    className="px-8  md:text-lg text-secondary font-normal mb-3 relative"
                    onClick={() => getChatByChatId(item.threadId, item.type)}
                  >
                    <Markdown>{item.snippet}</Markdown>
                  </p>
                </div>
                <div
                  className="px-8  flex items-center mt-3 border-b-0 border-borderColor"
                  onClick={() => getChatByChatId(item.threadId, item.type)}
                >
                  <p className="flex items-center mr-5 mb-5">
                    <Icon
                      icon="bx:layer-plus"
                      className="text-2xl text-secondary mr-2"
                    />
                    {item.numberOfQueries}
                  </p>
                  <p className="flex items-center mb-5">
                    <Icon
                      icon="wi:time-4"
                      className="text-2xl text-secondary mr-2"
                    />
                    {formattedDate}
                  </p>
                </div>
              </div>
            );
          })}
          {chatLoadStatus === "loading" && (
            <div className="icon-container ml-2 flex items-center justify-center">
              <Icon
                icon="lets-icons:progress"
                width="2.5rem"
                height="2.5rem"
                className="lets-icons progress"
              />
            </div>
          )}
        </div>
      </div>
      <DeleteModal
        setIsDeleteModal={setIsDeleteModal}
        isDeleteModal={isDeleteModal}
        chatIndexToDelete={deleteChat}
        onDelete={handleDeleteChat}
      />
    </>
  );
}

export default History;
