import "./App.css";
import { Routes, Route, Outlet } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import AnswerSearch from "./pages/AnswerSearch";
import ErrorPage from "./pages/ErrorPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DocumentSearch from "./pages/DocumentSearch";
import ChatHistory from "./pages/ChatHistory";
import Folder from "./pages/Folder";
import AccountDetails from "./pages/AcctountDetails";
import Team from "./pages/Team";
import Thread from "./pages/Thread";
import Teams from "./pages/Teams";
import PrivateRouteWrapper from "./Components/PrivateRouteWrapper";
import DashboardHeader from "./Components/DashdoardHeader";
import JoinToTeam from "./pages/JoinToTeam";
import DirectMessage from "./pages/DirectMessage";
import DMSPage from "./pages/DMSPage";
// import Auth from "./pages/Auth";
import CreateProfile from "./pages/CreateProfile/CreateProfile";
import WorkerEmailPage from "./pages/CreateProfile/WorkerEmailPage";
import EmailVerify from "./pages/verification/EmailVerify";
import VerificationSuccess from "./pages/verification/VerificationSuccess";
import EmailVerification from "./pages/verification/EmailVerification";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/auth" element={<Auth />} /> */}
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/create-profile/:token" element={<CreateProfile />} />
        <Route path="/worker-email/:token" element={<WorkerEmailPage />} />
        <Route path="/email/verification" element={<EmailVerification />} />
        <Route path="/email/verify/:token" element={<EmailVerify />} />
        <Route path="/email/success" element={<VerificationSuccess />} />
        <Route path="/invite/:token" element={<JoinToTeam />} />
        <Route element={<PrivateRouteWrapper />}>
          <Route element={
            <>
              <DashboardHeader />
              <Outlet />
            </>
          } >
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/team" element={<Team />} />
            <Route path="/dms" element={<DMSPage />} />
            <Route path="/thread" element={<Thread />} />
            <Route path="/teams" element={<Teams />} />
            <Route path="/dm" element={<DirectMessage />} />
            <Route path="/answer-search" element={<AnswerSearch />} />
            <Route path="/document-search/:documentId" element={<DocumentSearch />} />
            <Route path="/chat-history" element={<ChatHistory />} />
            <Route path="/folder" element={<Folder />} />
            <Route path="/account" element={<AccountDetails />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
