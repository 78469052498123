import React from "react";

// navbar

// Section1
import { RiArrowRightSFill } from "react-icons/ri";
import sec1 from "../assets/sec1.png";

// Section2
import { CiHeart } from "react-icons/ci";
import { GoDatabase } from "react-icons/go";
import { CiPercent } from "react-icons/ci";
import { TiMessages } from "react-icons/ti";

// Section3
import sec2 from "../assets/sec2.png";
import sec3 from "../assets/sec3.png";
import sec4 from "../assets/sec4.png";
import sec5 from "../assets/sec5.png";
import sec6 from "../assets/sec6.png";
import sec7 from "../assets/sec7.png";
import mobilesec7 from "../assets/mobile-sec7.png";
import { useNavigate } from "react-router-dom";
import quoromlogo from "../assets/logo-quorom.png";
import cospacelogo from "../../src/assets/logo-cospace.png"
import Header from "../Components/HomePage/Header";
import Footer from "../Components/HomePage/Footer";

function Home() {

  const navigate = useNavigate();

  const redirectButton = () => {
    navigate("/auth");
  };

  return (
    <>
      <Header />
      <div className="section1">
        <div className="Heading text-center md:mt-28 mt-16 mb-10 md:px-0 px-5">
          <h2 className="xl:text-5xl text-4xl font-semibold pb-3">
            AI-Powered Collaboration for Teams
          </h2>
          <p className="text-md text-[#7E7E7E] font-medium lg:w-1/3 md:w-2/3 w-full m-auto">
            Transform your team’s communication with
            <a
              href="https://www.cospace.ai/"
              className="text-[#8934E9] underline px-1"
            >
              Cospace.ai
            </a>
            , the platform that integrates multiple AI models into your
            conversations.
          </p>
        </div>
        <div className="demobtn text-center">
          <button
            type="button"
            className="bg-gradient-to-r from-[#8934E9] to-[#5844E6] text-white font-medium rounded-[50px] text-sm px-5 py-2.5 flex items-center m-auto"
          >
            Request A Demo <RiArrowRightSFill />
          </button>
        </div>
        <div className="demoimg lg:w-2/3 w-full m-auto">
          <img src={sec1} alt="" className="w-full object-cover" />
        </div>
      </div>

      {/* Section 2 */}

      <div className="section2 bg-[#D3CCF8] md:px-20 px-10 md:py-20 py-10">
        <div className="heading font-inter text-[18px] font-bold leading-[21.78px] md:text-center">
          <h2 className="font-inter md:text-[48px] text-[24px] font-bold md:leading-[58.09px] leading-[35px] md:text-center">
            Save Big on Team Collaboration software
          </h2>
        </div>
        <div className="sec2-cards flex items-center flex-wrap justify-between gap-10 pt-10">
          <div className="card flex items-center justify-center flex-col bg-white xl:w-[calc(25%-30px)] md:w-[calc(50%-20px)] w-full rounded-[30px] px-4 py-4 min-h-56">
            <div className="icon bg-[#f7f1ff] p-2 rounded-[50%] text-[#8934e9]  md:text-xl text-[32px] mb-2">
              <CiHeart />
            </div>
            <div className="heading font-inter text-[18px] font-bold leading-[21.78px] text-center">
              <h4>White-glove onboarding</h4>
            </div>
            <div className="info w-2/3 m-auto">
              <p className="font-inter text-[14px] font-normal leading-[16.94px] text-center text-[#7E7E7E]">
                Get best in class onboarding support for all plans regardless of
                the size of your organization
              </p>
            </div>
          </div>
          <div className="card flex items-center justify-center flex-col bg-white xl:w-[calc(25%-30px)] md:w-[calc(50%-20px)] w-full rounded-[30px] px-4 py-4 min-h-56 ">
            <div className="icon bg-[#f7f1ff] p-2 rounded-[50%] text-[#8934e9] md:text-xl text-[32px] mb-2">
              <GoDatabase />
            </div>
            <div className="heading font-inter text-[18px] font-bold leading-[21.78px] text-center">
              <h4>Custom Installation</h4>
            </div>
            <div className="info w-2/3 m-auto">
              <p className="font-inter text-[14px] font-normal leading-[16.94px] text-center text-[#7E7E7E]">
                Host cospace.ai in your private AWS cloud with Cospace.ai’s
                fat-rate custom installation option. Enjoy the security and
                cost-efectiveness for as low as $999
              </p>
            </div>
          </div>
          <div className="card flex items-center justify-center flex-col bg-white xl:w-[calc(25%-30px)] md:w-[calc(50%-20px)] w-full rounded-[30px] px-4 py-4 min-h-56 ">
            <div className="icon bg-[#f7f1ff] p-2 rounded-[50%] text-[#8934e9]  md:text-xl text-[32px] mb-2">
              <CiPercent />
            </div>
            <div className="heading font-inter text-[18px] font-bold leading-[21.78px] text-center">
              <h4>Simple Pricing</h4>
            </div>
            <div className="info w-2/3 m-auto">
              <p className="font-inter text-[14px] font-normal leading-[16.94px] text-center text-[#7E7E7E]">
                Our simple, transparent pricing ensures that small teams and
                growing organizations can enjoy AI-powered collaboration without
                breaking the bank.
              </p>
            </div>
          </div>
          <div className="card flex items-center justify-center flex-col bg-white xl:w-[calc(25%-30px)] md:w-[calc(50%-20px)] w-full rounded-[30px] px-4 py-4 min-h-56">
            <div className="icon bg-[#f7f1ff] p-2 rounded-[50%] text-[#8934e9] md:text-xl text-[32px] mb-2">
              <TiMessages />
            </div>
            <div className="heading font-inter text-[18px] font-bold leading-[21.78px] text-center">
              <h4>Smarter Conversations</h4>
            </div>
            <div className="info w-2/3 m-auto">
              <p className="font-inter text-[14px] font-normal leading-[16.94px] text-center text-[#7E7E7E]">
                With AI integrated into every chat, your team can ask complex
                questions, automate routine tasks, and gain insights instantly.
                It’s like having an AI assistant always on call.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Section 3 */}

      <div className="section3 md:py-20 py-10" id="features">
        <div className="whatisquorom flex md:flex-row flex-col items-center justify-center">
          <div className="logo max-w-[240px]">
            <img src={cospacelogo} alt="" className="w-full object-contain" />
          </div>
          <span className="text-2xl text-[#7E7E7E] font-bold ps-8 pe-12 md:py-0 py-5">
            =
          </span>
          <div className="flex">
            <p className="md:text-2xl text-xl text-[#2A2A2A] font-bold">
              Slack
            </p>
            <span className="md:text-2xl text-xl text-[#7E7E7E] font-bold md:px-10 px-5">
              +
            </span>
            <p className="md:text-2xl text-xl text-[#2A2A2A] font-bold">
              Chat GPT
            </p>
            <span className="md:text-2xl text-xl text-[#7E7E7E] font-bold md:px-10 px-5">
              +
            </span>
            <p className="md:text-2xl text-xl text-[#2A2A2A] font-bold">
              perplexity
            </p>
          </div>
        </div>
      </div>

      {/* Section 4 */}

      <div className="section4 ">
        <div className="row-1 md:flex items-center justify-center xl:px-40 lg:px-20  px-10 md:py-20 py-10 ">
          <div className="left md:w-1/2 w-full xl:ps-24 xl:pe-32 md:pe-5">
            <div className="heading">
              <h2 className="text-3xl text-[#2A2A2A] font-bold">
                Smart Team <br /> Collaboration
              </h2>
              <p className="text-sm  text-[#7E7E7E] font-medium">
                Say goodbye to cluttered channels. With
                <a
                  href="https://www.cospace.ai/"
                  className="text-[#8934E9] underline px-1"
                >
                  Cospace.ai
                </a>
                , conversations happen in threads, keeping discussions organized
                and easy to follow without the need to subscribe to entire
                channels. Quorum.ai comes with all the basic chat features such
                as group chats, DMs, notifcations, and fle sharing.
              </p>
            </div>
          </div>
          <div className="right md:w-1/2 xl:px-24 lg:px-0 px-0 md:pt-0 pt-5">
            <div className="img">
              <img src={sec2} alt="" />
            </div>
          </div>
        </div>
        <div className="row-1 flex md:flex-row flex-col items-center justify-center xl:px-40 lg:px-20  px-10 md:py-20 py-10  bg-[#F5F4F4]">
          <div className="left md:order-1 order-2 md:w-1/2 xl:px-24 lg:px-0 px-0 ">
            <div className="img">
              <img src={sec3} alt="" />
            </div>
          </div>
          <div className="right md:order-2 order-1 md:w-1/2 w-full xl:ps-24 xl:pe-32 md:ps-5 md:pb-0 pb-5">
            <div className="heading">
              <h2 className="text-3xl text-[#2A2A2A] font-bold">
                Real time Answer Engine
              </h2>
              <p className="text-sm  text-[#7E7E7E] font-medium">
                <a
                  href="https://www.cospace.ai/"
                  className="text-[#8934E9] underline px-1"
                >
                  Cospace.ai
                </a>
                includes a real-time answer engine that utilizes real time web
                data to provide accurate and timely responses to your queries.
              </p>
            </div>
          </div>
        </div>
        <div className="row-1 md:flex items-center justify-center xl:px-40 lg:px-20  px-10 md:py-20 py-10 ">
          <div className="left md:w-1/2 w-full xl:ps-24 xl:pe-32 md:pe-5">
            <div className="heading">
              <h2 className="text-3xl text-[#2A2A2A] font-bold">
                Your AI, Your Data
              </h2>
              <p className="text-sm  text-[#7E7E7E] font-medium">
                With
                <a
                  href="https://www.cospace.ai/"
                  className="text-[#8934E9] underline px-1"
                >
                  Cospace.ai
                </a>
                you can integrate with popular LLMs by simply using your own API
                key.
              </p>
            </div>
          </div>
          <div className="right md:w-1/2 xl:px-24 lg:px-0 px-0 md:pt-0 pt-5">
            <div className="img">
              <img src={sec4} alt="" />
            </div>
          </div>
        </div>
        <div className="row-1 flex md:flex-row flex-col items-center justify-center xl:px-40 lg:px-20  px-10 md:py-20 py-10   bg-[#F5F4F4]">
          <div className="left md:order-1 order-2 md:w-1/2 xl:px-24 lg:px-0 px-0">
            <div className="img">
              <img src={sec5} alt="" />
            </div>
          </div>
          <div className="right md:order-2 order-1 md:w-1/2 w-full xl:ps-24 xl:pe-32 md:ps-5 md:pb-0 pb-5">
            <div className="heading">
              <h2 className="text-3xl text-[#2A2A2A] font-bold">
                Chat with Documents
              </h2>
              <p className="text-sm  text-[#7E7E7E] font-medium">
                Make
                <a
                  href="https://www.cospace.ai/"
                  className="text-[#8934E9] underline px-1"
                >
                  Cospace.ai
                </a>
                truly your own. Customize the logo and color palette to match
                your organization’s branding. Whether it's your private cloud
                installation or public SaaS instance, Cospace.ai adapts to your
                company's identity.
              </p>
            </div>
          </div>
        </div>

        <div className="row-1 md:flex items-center justify-center xl:px-40 lg:px-20  px-10 md:py-20 py-10  ">
          <div className="left md:w-1/2 w-full xl:ps-24 xl:pe-32 md:pe-5">
            <div className="heading">
              <h2 className="text-3xl text-[#2A2A2A] font-bold">
                Custom Branding
              </h2>
              <p className="text-sm  text-[#7E7E7E] font-medium">
                Make
                <a
                  href="https://www.cospace.ai/"
                  className="text-[#8934E9] underline px-1"
                >
                  Cospace.ai
                </a>
                truly your own. Customize the logo and color palette to match
                your organization’s branding. Whether it's your private cloud
                installation or public SaaS instance, Cospace.ai adapts to your
                company's identity.
              </p>
            </div>
          </div>
          <div className="right md:w-1/2 xl:px-24 lg:px-0 px-0 md:pt-0 pt-5">
            <div className="img">
              <img src={sec6} alt="" />
            </div>
          </div>
        </div>

        <div className="row-1 xl:px-40 lg:px-20 px-10 md:py-20 py-10 bg-[#F5F4F4]" id="pricing">
          <div className="heading">
            <h2 className="font-inter md:text-[48px] text-[28px] font-bold md:leading-[58.09px] leading-10 text-center">
              Simple, Transparent Pricing
            </h2>
          </div>
          <div className="pricecards w-full md:flex items-center justify-center xl:px-40 lg:px-20 px-5 md:py-20 py-10 gap-10 pt-10">
            <div className="card md:w-1/2 w-full bg-white min-h-[20rem]">
              <div className="card-body p-8">
                <div className="heading">
                  <h2 className="text-3xl text-[#2A2A2A] font-medium">
                    Standard
                  </h2>
                  <p className="text-[12px] mt-2 text-[#7E7E7E] font-medium">
                    Make
                    <a
                      href="https://www.cospace.ai/"
                      className="text-[#8934E9] underline px-1"
                    >
                      Cospace.ai
                    </a>
                    truly your own. Customize the logo and color palette to
                    match your organization’s branding.
                  </p>
                </div>
                <div className="info pt-16">
                  <div className="price">
                    <h3 className="text-4xl text-[#2A2A2A] font-bold">$5</h3>
                    <p className="text-sm  text-[#7E7E7E] font-medium">
                      User Per Month / Paid annually
                    </p>
                  </div>

                  <div className="signup-btn pt-4">
                    <button onClick={redirectButton} className="border-2 border-[#7E7E7E] w-full py-2 rounded-[30px] text-[#8934E9] flex items-center justify-center">
                      Sign Up <RiArrowRightSFill className="text-xl" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card md:w-1/2 w-full bg-white min-h-[20rem] md:mt-0 mt-5">
              <div className="card-body p-8">
                <div className="heading">
                  <h2 className="text-3xl text-[#2A2A2A] font-medium">
                    Custom
                  </h2>
                  <p className="text-[12px] mt-2 text-[#7E7E7E] font-medium">
                    Make
                    <a
                      href="https://www.cospace.ai/"
                      className="text-[#8934E9] underline px-1"
                    >
                      Cospace.ai
                    </a>
                    truly your own. Customize the logo and color palette to
                    match your organization’s branding.
                  </p>
                </div>
                <div className="signup-btn mt-36">
                  <button className="border-2 border-[#7E7E7E] w-full py-2 rounded-[30px] text-[#8934E9] flex items-center justify-center">
                    Contact Sales <RiArrowRightSFill className="text-xl" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section 5 */}

      <div className="section5 md:p-20 p-10">
        <div className="container-section lg:ps-20 ps-5  lg:pe-5 md:py-0 py-10 overflow-hidden rounded-[70px] bg-gradient-to-r from-[#8934E9] to-[#5844E6] lg:flex items-center justify-center">
          <div className="left text-white lg:w-1/2 w-full xl:px-20 md:px-10 px-5">
            <h2 className="font-inter lg:text-[38px] md:text-[24px] text-[18px] font-bold lg:leading-[58.09px] md:leading-7 ">
              Join the AI-Powered Collaboration Revolution
            </h2>
            <p className="md:text-[14px] text-[12px] mt-2 font-medium">
              <span className="text-white font-bold">Cospace.ai</span> combines
              the core chat features you expect with cutting-edge AI tools that
              streamline your workfow and enhance team collaboration
            </p>

            <button
              type="button"
              className=" text-[#8934E9] bg-white font-medium rounded-[50px] text-sm px-5 py-2.5 flex items-center mt-4"
            >
              Request A Demo <RiArrowRightSFill className="text-xl" />
            </button>
          </div>

          <div className="right lg:w-1/2 w-full lg:pt-0 pt-10">
            <div className="img">
              <img
                src={sec7}
                alt=""
                className="lg:max-h-[30rem] w-full lg:block hidden"
              />
              <img
                src={mobilesec7}
                alt=""
                className="lg:max-h-[30rem] w-full md:px-10 px-5 lg:hidden"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;

