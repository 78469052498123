import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { SocketProvider } from "../context/SocketProvider";
import { jwtDecode } from "jwt-decode";

function PrivateRouteWrapper() {
  const token = localStorage.getItem("authToken");
  let decodedToken = "";

  if (token) {
    decodedToken = jwtDecode(token);
  }

  if (!decodedToken?.userId) {
    return <Navigate to="/" />;
  }

  return (
    <SocketProvider>
      <Outlet />
    </SocketProvider>
  );
}

export default PrivateRouteWrapper;
