import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";
import Header from "../../Components/HomePage/Header";
import apiClient from "../../utils/axiosInstance";

const validationSchema = Yup.object().shape({
  emails: Yup.array().of(
    Yup.string().email("Invalid email").required("Email is required")
  ),
});

function WorkerEmailPage() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    try {
      const decodeToken = jwtDecode(token);
      if (decodeToken.email) {
        setUserEmail(decodeToken.email);
      } else {
        navigate("/auth");
      }
    } catch (error) {
      console.log("Token Error:", error);
      navigate("/auth");
    }
  }, [token, navigate]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsSubmitting(true);
    try {
      await apiClient().post(`/invite-user-to-organization`, {
        emails: values.emails.filter((email) => email.trim() !== ""),
        email: userEmail,
        isSubscribe: false,
      });
      await redirectToDashboard();
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
      setSubmitting(false);
    }
  };

  const redirectToDashboard = async () => {
    try {
      const decodeToken = jwtDecode(token);
      const response = await apiClient().post("/login", {
        email: decodeToken.email,
        isDirectToLogin: true
      })
      if (response && response.data) {
        localStorage.setItem("authToken", response.data.token);
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  }

  return (
    <>
      <Header />
      <div
        className="flex items-center justify-center"
        style={{ height: "calc(100vh - 81px)" }}
      >
        <div className="bg-white p-6 rounded w-full max-w-lg text-center">
          <h2 className="text-center text-2xl font-bold mb-5">
            Get more done with your team.
          </h2>
          <Formik
            initialValues={{ emails: [""] }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched }) => (
              <Form>
                <FieldArray name="emails">
                  {({ push, remove }) => (
                    <div>
                      {values.emails.map((email, index) => (
                        <div key={index} className="mb-4">
                          <Field
                            name={`emails.${index}`}
                            type="email"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
                            placeholder="co-worker email"
                          />
                          {errors.emails &&
                            errors.emails[index] &&
                            touched.emails &&
                            touched.emails[index] && (
                              <div className="text-red-500 text-sm mt-1">
                                {errors.emails[index]}
                              </div>
                            )}
                        </div>
                      ))}
                      <div className="mb-4">
                        <button
                          type="button"
                          onClick={() => push("")}
                          className="w-full bg-gray-200 text-gray-700 py-2 px-4 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                        >
                          Add More
                        </button>
                      </div>
                    </div>
                  )}
                </FieldArray>
                <div className="flex items-center justify-between">
                  <button
                    type="button"
                    onClick={redirectToDashboard}
                    className="w-40 bg-white text-lendingButton border-2 border-lendingButton py-2 px-4 mr-5 rounded"
                  >
                    Skip
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-1/2 bg-lendingButton text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:bg-green-700 ml-4"
                  >
                    {isSubmitting ? "inviting..." : "Complete"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default WorkerEmailPage;
