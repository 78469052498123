import Sidebar from "../Components/Sidebar";
import History from "../Components/ChatHistory/history";
import DashboardSidebar from "./DashboardSidebar";
import { useAppSelector } from "../store";
import { PuffLoader } from "react-spinners";

function ChatHistory() {
  const { userDetailsState, organizationState } = useAppSelector((state) => ({
    userDetailsState: state.auth.userDetails,
    organizationState: state.organization.organization
  }))
  return (
    <>
      {
        (userDetailsState && organizationState.length > 0) ?
          <div>
            <div className="flex md:flex-row flex-col">
              <DashboardSidebar />
              <Sidebar />
              <div className="bg-backgroundColor  header-height flex justify-center items-center md:px-0 px-2 my-pb-20">
                <div className=" rounded-lg sm:w-full md:w-full lg:w-full">
                  <div className="relative">
                    <div className="header-height overflow-y-hidden lg:h-[100%] xl:h-[100vh] w-full">
                      <History />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="spinner h-screen flex items-center justify-center">
            <PuffLoader

              cssOverride={null}
              loading
              size={60}
            />
            <h1 className="text-3xl ms-3 font-bold">Loading...</h1>
          </div>
      }

    </>
  );
}

export default ChatHistory;